import React, { useEffect, useState } from "react";
import Toolbar from "../../components/Navigarion/Toolbar/Toolbar";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Button from "../../components/UI/Button/Button";
//import { Hidden } from 'aws-amplify-react/lib-esm/AmplifyTheme';
import SpinnerLittle from "../../components/UI/Spinner/SpinnerLittle";
import Input from "../../components/UI/Imput/Imput";
import ExamSelector from "../../components/UI/ExamSelector/ExamSelector";
import api from "../../services/api";
import { notification } from "antd";
import { getApiErrorMessage } from "../../utils/apiError";
import { formatCpf } from "../../helpers/helper";

const callsLimit = 5

const ESolverServerStatus = {
	LOADING: { code: 1 },
	ONLINE: { code: 2 },
	OFFLINE: { code: 3 },
}

const cpfInputOptions = {
	mask: "999.999.999-99",
	placeholder: "___.___.___-__",
	clearIncomplete: true,
}

const elementConfig = {
	placeholder: "___.___.___-__"
}

const AwaitComponent = () => {
	return (
		<div className="loading-results">
			<SpinnerLittle />
			Aguarde estamos buscando os resultado.
		</div>
	)
}

const SlowAwaitComponent = () => {
	return (
		<div className="loading-results">
			<SpinnerLittle />
			Estamos com dificuldade para calcular o resultado, volte dentro de alguns minutos.
		</div>
	)
}

const Report = (props) => {
	const history = useHistory();

	const [acquisition, setAcquisition] = useState();
	const [waitMens, setWaitMens] = useState(true)
	const [awaitComponent, setAwaitComponent] = useState(<AwaitComponent />)

	const [showForm, setShowForm] = useState(true);
	const [showExamsSelector, setShowExamsSelector] = useState(false);
	const [id, setId] = useState({
		value: "",
		valid: false,
	});
	const [cpf, setCpf] = useState({
		value: "",
		valid: false,
	});

	const [showFilter, setShowFilter] = useState(false);
	const [newExams, setNewExams] = useState([]);
	const [notVinculatedExams, setNotVinculatedExams] = useState([]);
	const [vinculatedExams, setVinculatedExams] = useState([]);

	const [filterArray, setFilterArray] = useState(null);
	const [filteredResult, setFilteredResult] = useState(null);

	const [solveServerOnline, setSolveServerOnline] = useState(ESolverServerStatus.LOADING.code)

	useEffect(() => {
		console.log('Linha 75 - Report.js')
		const filterStorage = null // localStorage.getItem("progenos-filter");
		if (filterStorage) {
			console.log('Linha 78 - Report.js')
			setFilterArray(JSON.parse(filterStorage));
		}
	}, []);

	useEffect(() => {
		console.log('Linha 84 - Report.js')
		if (filterArray != null) {
			console.log('Linha 86 - Report.js')
			localStorage.setItem("progenos-filter", JSON.stringify(filterArray));
		}
	}, [filterArray]);

	const [personData, setPersonData] = useState({
		id: 0,
		idade: 0,
		sexo: "--",
		etnia: "--",
		data: "--",
		nome: "--",
		cpf: "--"
	});

	const [resultados, setResultados] = useState(null);

	useEffect(() => {
		console.log('Linha 103 - Report.js')
		if (resultados != null) {
			console.log('Linha 105 - Report.js')
			let filteredResult = setFilterObj({ ...resultados }, newExams.length > 0 ? null : filterArray);
			setFilteredResult(filteredResult);
			setNewExams([])
		}
	}, [resultados]);

	useEffect(() => {
		console.log('Linha 113 - Report.js')
		verifySolveServer()
	}, [])

	const verifySolveServer = async () => {
		try {
			const result = await api.server.verification()

			if (!result || !result.data.success) {
				setSolveServerOnline(ESolverServerStatus.OFFLINE.code)
				return
			}

			setSolveServerOnline(ESolverServerStatus.ONLINE.code)
		} catch (error) {
			console.log(error)
			setSolveServerOnline(ESolverServerStatus.OFFLINE.code)
		}
	}

	const downloadSamples = () => {
		if (typeof acquisition !== "undefined") {
			let csvContent = "data:text/csv;charset=utf-8,";
			let csvContentHomo = "data:text/csv;charset=utf-8,";
			//let selectedFilter = this.state.prevs.map(el=>el.sel && el);

			const opts = acquisition.searchResult.opts;
			const ntcs = acquisition.searchResult.tpr;
			const black = acquisition.searchResult.black;
			const id = acquisition.searchResult.personData.id;

			const acquisitionHomo = acquisition.searchResult.acquisitionHomo;

			const seq = opts.map((v, i) => i + 1);
			const seqTp = ntcs.map((v, i) => i + 1);
			const seqBlack = black.map((v, i) => i + 1);

			const seqHomo = acquisitionHomo.map((v, i) => i + 1);

			csvContent +=
				"id," + seqBlack.toString() + "," + seq.toString() + "," + seqTp + "\n";
			csvContent +=
				id +
				"," +
				black.toString() +
				"," +
				opts.toString() +
				"," +
				ntcs.toString() +
				"\n";

			csvContentHomo += "id," + seqHomo + "\n";
			csvContentHomo += id + "," + acquisitionHomo + "\n";

			let encodedUri = encodeURI(csvContent);
			let encodedUriHomo = encodeURI(csvContentHomo);

			let link = document.createElement("a");
			let link2 = document.createElement("a");
			link.setAttribute("href", encodedUri);
			link2.setAttribute("href", encodedUriHomo);
			link.setAttribute("download", "data.csv");
			link2.setAttribute("download", "dataHomo.csv");
			document.body.appendChild(link);
			document.body.appendChild(link2);
			link.click();
			link2.click();
		}
	};

	const diaDHandler = () => {
		try {
			console.log("Ativando o Dia D...");
			const resultD = {
				bios: [
					{
						subGroup: [
							{
								name: "Outros",
								markers: [resultados.bios[1].subGroup[1].markers[4]],
							},
						],
						title: "Análise Bioquímico",
					},
				],
			};

			setResultados(resultD);
			console.log("Dia D ativado!!");
		} catch (error) {
			console.log(error);
		}

		//resultados.bios[1].subGroup[1].markers[4]
	};

	const searchRegestry = async (_id, prontuario, _company) => {
		console.log('Linha 210 - Report.js')
		let query = {
			queryStringParameters: {
				cpf: prontuario,
				id: _id,
				company: _company,
			},
		};

		//personalID=77&acquisitionID=77
		API.get("aquisitor-api", "/acquisition", query)
			.then((response) => {
				console.log('Linha 222 - Report.js')
				if (response.statusCode === 200) {
					setAcquisition({
						searchResult: response.body,
					});

					if (response?.body?.personData) {
						setPersonData({ ...response.body.personData, cpf: cpf });
					}
				}
			})
			.catch((error) => {
				console.log("Erro");
				console.log('Erro Linha 235 - Report.js')
				console.log(JSON.stringify(error))
			});

		getResult(prontuario, _id);
	};

	const getResult = async (cpf, id, calls = 1) => {
		let jwtToken = null
		let params = null
		try {
			jwtToken = await (await Auth.currentSession())
				.getIdToken()
				.getJwtToken();
			params = {
				register_number: cpf,
				progenos_id: id,
				progenos_api_token: jwtToken,
			};
		} catch (error) {
			console.log('Erro Linha 246 - Report.js')
			console.log(JSON.stringify(error))
			throw error
		}

		try {

			console.log('Linha 262 - Report.js')

			if (solveServerOnline === ESolverServerStatus.ONLINE.code) {
				console.log('Linha 265 - Report.js')
				const { data } = await api.sampleCollection.getResults(params);
				console.log('Linha 267 - Report.js')
				console.log('Retorno da API: ', JSON.stringify(data))
				if (data?.shouldReload) {
					const cb = () => getResult(cpf, id, calls + 1)
					reloadCalls(cb, calls)

					return
				}

				console.log('Linha 276 - Report.js')

				if (data?.body?.bios) {
					resetLoadingStates()
					setResultados(data.body);
				} else {
					throw new Error('Dados não econtrados. ')
				}
			} else {
				console.log('Linha 285 - Report.js')

				const query = {
					queryStringParameters: {
						personalID: cpf,
						acquisitionID: id
					}
				};

				API.get('result-api', '/', query).then(response => {
					if (response.statusCode == 200) {
						if (!response?.body?.bios) {
							const cb = () => getExams(cpf, id, calls + 1)
							reloadCalls(cb, calls)

							return
						}

						resetLoadingStates()
						setResultados(response.body);

						history.push(`/report?prontuario=${cpf}&id=${id}`)
					}

				}).catch(error => {
					console.log('Erro Linha 310 - Report.js')
					console.log(JSON.stringify(error))
					console.log(error)
				})
			}

		} catch (error) {
			console.log('Erro Linha 317 - Report.js')
			console.log(JSON.stringify(error))
			resetLoadingStates()
			notification.error({
				message: "Erro ao buscar exames.",
				description: getApiErrorMessage(error)
			});
		}
	};

	function resetLoadingStates() {
		setWaitMens(false)
		setAwaitComponent(<AwaitComponent />)
	}

	function reloadCalls(cb, calls) {
		if (calls === callsLimit) {
			setAwaitComponent(<SlowAwaitComponent />)
		}

		console.log("reload", calls)

		setTimeout(() => {
			cb()
		}, [15000])

		return
	}

	const setFilterObj = (results, filterArray) => {
		try {
			const indexOfShowParam = 7;
			const filterObj = results;
			if (filterArray == null) {
				for (let groupIndex = 0; groupIndex < results.bios.length; groupIndex++) {
					const group = results.bios[groupIndex];
					results.bios[groupIndex].show = true;
					for (
						let subGroupIndex = 0;
						subGroupIndex < group.subGroup.length;
						subGroupIndex++
					) {
						const subGroup = group.subGroup[subGroupIndex];
						filterObj.bios[groupIndex].subGroup[subGroupIndex].show = true;
						for (
							let markerIndex = 0;
							markerIndex < subGroup.markers.length;
							markerIndex++
						) {
							filterObj.bios[groupIndex].subGroup[subGroupIndex].markers[
								markerIndex
							][indexOfShowParam] = true;
						}
					}
				}
				setFilterArray(filterObj);
			} else {
				for (let groupIndex = 0; groupIndex < results.bios.length; groupIndex++) {
					const group = results.bios[groupIndex];
					results.bios[groupIndex].show = true;
					for (
						let subGroupIndex = 0;
						subGroupIndex < group.subGroup.length;
						subGroupIndex++
					) {
						const subGroup = group.subGroup[subGroupIndex];
						filterObj.bios[groupIndex].subGroup[subGroupIndex].show =
							filterArray.bios[groupIndex].subGroup[subGroupIndex].show;
						for (
							let markerIndex = 0;
							markerIndex < subGroup.markers.length;
							markerIndex++
						) {
							if (
								filterArray.bios[groupIndex].subGroup[subGroupIndex].markers[
								markerIndex
								]
							) {
								filterObj.bios[groupIndex].subGroup[subGroupIndex].markers[
									markerIndex
								][indexOfShowParam] =
									filterArray.bios[groupIndex].subGroup[subGroupIndex].markers[
									markerIndex
									][indexOfShowParam];
							}
						}
					}
				}
			}

			return filterObj;
		} catch (error) {
			console.log('Erro Linha 409 - Report.js')
			console.log(JSON.stringify(error))
		}
		
	};

	const updateCpf = (e) => {
		let newValue = {
			value: e.target.value,
			valid: false,
		};

		if (cpf.value.length > 0 && e.target.value.length > 0) {
			newValue.valid = true;
		}

		setCpf(newValue);
	};

	const updateId = (e) => {
		let newValue = {
			value: e.target.value,
			valid: false,
		};
		if (cpf.value.length > 0 && e.target.value.length > 0) {
			newValue.valid = true;
		}
		setId(newValue);
	};

	const buscaResultado = async () => {
		setShowForm(false);
		if (id.valid) {
			history.push(`/report?select-exams`);
			setShowForm(false)
			setWaitMens(true)
			await getExams(cpf.value.replace(/[.-]/g, ''), id.value);

		} else {
			history.push("/acquisition?prontuario=" + cpf.value);
		}
	};

	const vinculeNewExams = async () => {
		try {
			if (newExams.length > 0) {
				const body = {
					register_number: cpf.value.replace(/[.-]/g, ''),
					progenos_id: id.value,
					medical_exams_to_add: newExams,
				};
				await api.sampleCollection.selectNewExams(body);
			}

			history.push(`/report?prontuario=${cpf.value}&id=${id.value}`);
			setShowExamsSelector(false);
		} catch (error) {
			notification.error({
				message: "Erro ao solicitar exames.",
				description: getApiErrorMessage(error)
			});
		}
	};

	const printRedferenceValues = (bioA, bioB) => {
		const bioAMultLine = Array.isArray(bioA)
			? bioA.map((line) => (
				<>
					{line}
					<br />
				</>
			))
			: bioA;
		const bioBMultLine = Array.isArray(bioB)
			? bioB.map((line) => (
				<>
					{line}
					<br />
				</>
			))
			: bioB;

		if (bioA === bioB) {
			return <td colSpan="2">{bioAMultLine}</td>;
		} else {
			return (
				<>
					<td>{bioAMultLine}</td>
					<td>{bioBMultLine}</td>
				</>
			);
		}
	};

	// const checkFilterValue = (name) => {
	//     const item = filterArray.filter(item=>item===name)
	//
	//     console.log(item)
	//     return item.length ? item[0].show : false
	// }

	const changeFilterArray = (e) => {
		let newArrayFilter = { ...filteredResult };
		for (let i = 0; i < filterArray.bios.length; i++) {
			for (let y = 0; y < filterArray.bios[i].subGroup.length; y++) {
				const filter_to_consider = solveServerOnline == ESolverServerStatus.ONLINE.code ? filterArray.bios[i].title : filterArray.bios[i].subGroup[y].name
				if (filter_to_consider === e.target.id) {
					newArrayFilter.bios[i].subGroup[y].show =
						!newArrayFilter.bios[i].subGroup[y].show;

					for (
						let x = 0;
						x < newArrayFilter.bios[i].subGroup[y].markers.length;
						x++
					) {
						newArrayFilter.bios[i].subGroup[y].markers[x][6] =
							newArrayFilter.bios[i].subGroup[y].show;
					}
					break;
				}
				for (
					let x = 0;
					x < newArrayFilter.bios[i].subGroup[y].markers.length;
					x++
				) {
					if (
						newArrayFilter.bios[i].subGroup[y].markers[x][0] === e.target.id
					) {
						newArrayFilter.bios[i].subGroup[y].markers[x][6] =
							!newArrayFilter.bios[i].subGroup[y].markers[x][6];
						if (!newArrayFilter.bios[i].subGroup[y].show) {
							newArrayFilter.bios[i].subGroup[y].show = true;
						}
						break;
					}
				}
			}

			filterArray.bios[i].show =
				filterArray.bios[i].subGroup.filter((group) => group.show).length > 0
					? true
					: false;
		}
		setFilterArray(newArrayFilter);
	};

	const getExams = async (cpf, id, calls = 1) => {
		try {
			const jwtToken = await (await Auth.currentSession())
				.getIdToken()
				.getJwtToken();

			if (solveServerOnline === ESolverServerStatus.ONLINE.code) {
				const body = {
					register_number: cpf,
					progenos_id: id,
					progenos_api_token: jwtToken,
				};

				const { data } = await api.sampleCollection.getExamsToNewSelection(body);

				if (data?.shouldReload) {
					const cb = () => getExams(cpf, id, calls + 1)
					reloadCalls(cb, calls)

					return
				}

				resetLoadingStates()
				setNotVinculatedExams(data?.not_vinculeds?.groups ?? []);
				setVinculatedExams(data?.vinculeds?.groups ?? []);
				setShowExamsSelector(true);
			} else {
				const query = {
					queryStringParameters: {
						personalID: cpf,
						acquisitionID: id
					}
				};

				API.get('result-api', '/', query).then(response => {

					if (response.statusCode == 200) {
						if (!response?.body?.bios) {
							const cb = () => getExams(cpf, id, calls + 1)
							reloadCalls(cb, calls)

							return
						}

						resetLoadingStates()
						setNotVinculatedExams([]);
						setVinculatedExams([]);
						// setShowForm(false)
						// setShowExamsSelector(true);
						history.push(`/report?prontuario=${cpf}&id=${id}`)
					}

				}).catch(error => {
					console.log(error)
				})
			}
		} catch (error) {
			resetLoadingStates()
			notification.error({
				message: "Erro ao buscar exames.",
				description: getApiErrorMessage(error)
			});
		} finally {
		}
	};

	const printZeroObs = (bios) => {
		const biosWithZero = bios.every((bio) => bio[6] !== "*");
		if (biosWithZero) {
			return <></>;
		} else {
			return (
				<p>
					* Os resultados obtidos para os seus exames estão abaixo dos limites
					de detecção determinada para esse método.
				</p>
			);
		}
	};

	const printTable = (resultados) => {
		if (resultados == null) {
			return <></>;
		}
		return resultados.bios.map((group) => {
			if (group.show || showFilter) {

				if (group?.subGroup?.every(subGroup => subGroup?.markers?.length == 0)) {
					return <></>
				}

				return (
					<div key={group.title} className="grupo-exames">
						{solveServerOnline != ESolverServerStatus.ONLINE.code && <h4>{group.title}</h4>}
						{group.title && solveServerOnline == ESolverServerStatus.ONLINE.code ? (
							<h4>
								{showFilter ? (
									<>
										<input
											checked={group.show}
											id={group.title}
											onChange={changeFilterArray}
											type="checkbox"
											value={group.title}
										/>{" "}
										-
									</>
								) : (
									<></>
								)}{" "}
								{group.title}
							</h4>
						) : (
							<></>
						)}
						{group.subGroup.map((subBio) => {
							if (!subBio.markers.length) {
								return <></>;
							} else if (subBio.show || showFilter) {
								return (
									<div key={subBio.name} className="sub-grupo">
										{subBio.name ? (
											<h5>
												{showFilter ? (
													<>
														<input
															checked={subBio.show}
															id={subBio.name}
															onChange={changeFilterArray}
															type="checkbox"
															value={subBio.name}
														/>{" "}
														-
													</>
												) : (
													<></>
												)}{" "}
												{subBio.name}
											</h5>
										) : (
											<></>
										)}
										<table>
											<thead>
												<tr>
													<th></th>
													<th></th>
													<th></th>
													<th colSpan="2">Valores referência</th>
												</tr>
												<tr>
													<th></th>
													<th></th>
													<th></th>
													<th>Homem</th>
													<th>Mulher</th>
												</tr>
											</thead>

											<tbody>
												{subBio.markers.map((bio) => {
													if (bio[6] || showFilter) {
														return (
															<tr key={bio[0]}>
																<td>
																	{showFilter ? (
																		<>
																			<input
																				checked={bio[6]}
																				id={bio[0]}
																				onChange={changeFilterArray}
																				type="checkbox"
																				value={bio[0]}
																			/>{" "}
																			-
																		</>
																	) : (
																		""
																	)}{" "}
																	{bio[0]}
																</td>
																{/* <td>{(Math.floor(Math.random() * (bio[4] - bio[3])) + bio[3]).toLocaleString("br-PT")} </td>    */}
																<td>{bio[6]} </td>
																<td>{bio[1]}</td>
																{printRedferenceValues(bio[2], bio[3])}
															</tr>
														);
													}
													return <></>;
												})}
											</tbody>
										</table>
										{printZeroObs(subBio.markers)}
									</div>
								);
							}
						})}
					</div>
				);
			} else {
				<></>;
			}
		});
	};

	useEffect(() => {

		if (solveServerOnline !== ESolverServerStatus.LOADING.code) {
			Auth.currentAuthenticatedUser({
				bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			})
				.then((user) => {
					console.log('Linha 744 - Report.js')
					const parsedQuery = queryString.parse(props.location.search);
					const { id, prontuario, cpf } = parsedQuery;

					if ((id && cpf) || (id && prontuario)) {
						setShowForm(false);
						searchRegestry(
							id,
							cpf ? cpf.replace(/[.-]/g, '') : prontuario.replace(/[.-]/g, ''),
							user.attributes["custom:company"]
						);
					}
				})
				.catch((err) => {
					console.log('Erro Linha 758 - Report.js')
					console.log(JSON.stringify(err))
					return err;
				});
		}

	}, [props.location.search, solveServerOnline]);

	const filterHandler = () => {
		setShowFilter((showFilter) => !showFilter);
	};

	if (showForm) {
		return (
			<div className="report-main">
				<Toolbar userName={"usuario"} />

				<div className={"busca-registro"}>
					<h4>Insira os dados para buscas.</h4>
					<form>
						<Input
							elementConfig={elementConfig}
							label={"CPF"}
							elementType={"input"}
							value={cpf.value}
							invalid={cpf.valid}
							shouldValidate={false}
							changed={(e) => updateCpf(e)}
							maskOptions={cpfInputOptions}
							mask={'cpf'}
						/>
						
						<Input
							label={"ID"}
							elementType={"input"}
							value={id.value}
							invalid={id.valid}
							shouldValidate={false}
							changed={(e) => updateId(e)}
						/>

						<Button
							className={"classic"}
							disabled={!cpf.valid}
							clicked={buscaResultado}
							typeype="success"
							onClick
						>
							Buscar
						</Button>
					</form>
				</div>
			</div>
		);
	} else if (showExamsSelector) {
		return (
			<div className="report-main">
				<Toolbar userName={"usuario"} />

				{vinculatedExams.length > 0 && (
					<>
						<h2>Exames solicitados:</h2>
						<ExamSelector
							onChangeSelectedExams={() => null}
							disabled={true}
							groups={vinculatedExams}
						/>
					</>
				)}

				{notVinculatedExams.length > 0 && (
					<>
						<h2>Exames não solicitados:</h2>
						<ExamSelector
							onChangeSelectedExams={(e) => setNewExams(e)}
							groups={notVinculatedExams}
						/>
					</>
				)}

				<div style={{ display: "flex", justifyContent: "end" }}>
					<Button
						className={"classic"}
						disabled={!cpf.valid}
						clicked={() => vinculeNewExams()}
						typeype="success"
						onClick
					>
						{newExams.length === 0 ? "Continuar" : "Selecionar novos exames"}
					</Button>
				</div>
			</div>
		);
	} else {
		return (
			<div className="report-main">
				<Button clicked={diaDHandler} className="dia-d">
					D
				</Button>
				<Toolbar userName={"usuario"} />

				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<table className="report-info">
						<thead>
							<tr>
								<th>ID</th>
								<th>CPF</th>
								<th>Data de nascimento</th>
								<th>Etnia</th>
								<th>Sexo</th>
								<th>Data Aquisição</th>
								<th>Nome</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{personData?.id ?? 0}</td>
								<td>{formatCpf(personData?.cpf) ?? '--'}</td>
								<td>{personData?.idade ?? 0}</td>
								<td>{personData?.etnia ?? '--'}</td>
								<td>{personData?.sexo ?? '--'}</td>
								<td>{personData?.data ?? '--'}</td>
								<td>{personData?.nome ?? '--'}</td>
							</tr>
						</tbody>
					</table>

					{
						filteredResult &&
						<div className="filtro">
							<Button clicked={filterHandler}>
								{showFilter ? "Aplicar Filtro" : "Filtrar"}
							</Button>
						</div>
					}
				</div>

				{(!filteredResult || waitMens) && awaitComponent}

				{showFilter && <p style={{ marginLeft: 20, marginTop: 20 }}>Selecione os exames para fazer o filtro.</p>}
				{filteredResult && printTable(filteredResult)}
        
        <p>A tecnologia Progenos está em constante desenvolvimento e os resultados dos exames são obtidos por método de inteligência artificial associado a espectroscopia de infravermelho próximo, pelo mapeamento do centro da palma da mão. Os resultados têm por finalidade a triagem, não se recomenda utilizar os resultados das análises como diagnostico na decisão soberana do profissional médico.</p>
				<p>
					<Button className="hideTrick" clicked={downloadSamples}>
						.
					</Button>
				</p>
			</div>
		);
	}
};

export default Report;
